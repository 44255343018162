import { UniqueDirectiveNamesRule } from "graphql";
import * as fb from "@/firebase/index.js";
import router from "../../router";
import axios from "axios";
const crypto = require("crypto");
import HeartbeatClient from 'ars-hbclient';
export default {
  state: {
    kioskProfile: {},
    kioskId: undefined,
    sessionId: null,
    count: 0,
    dataloadInit: false,
    qnaEndpointEN: {},
  },
  getters: {
    getKioskId: (state) => state.kioskId,
    getSessionId: (state) => state.sessionId,
    getKioskProfile: (state) => state.kioskProfile,
    getCMSlink: (state) => state.kioskProfile.data().cmsLink,
    getQnaEndpointEN: (state) => state.qnaEndpointEN,
    getSitepalID: (state) => state.kioskProfile.data().sitepalId,
    getAvatarID: (state) => state.kioskProfile.data().avatarId,
    getDataLoadInit: (state) => state.dataloadInit,
  },
  mutations: {
    setKioskId(state, kioskId) {
      state.kioskId = kioskId;
    },
    setSessionId(state, sessionId) {
      console.log("Session ID generated: ", sessionId);
      state.sessionId = sessionId;
    },
    setKioskProfile(state, profile) {
      state.kioskProfile = profile;
    },
    setDataloadInit(state, status) {
      state.dataloadInit = status;
    },
    setQnaEndpointEN(state, qnaObj) {
      console.log("qnaObj: ", qnaObj);
      state.qnaEndpointEN.enable = qnaObj.enable;
      state.qnaEndpointEN.kbName = qnaObj.kbName;
    },
  },
  actions: {
    login({ dispatch }, loginObj) {
      return new Promise((resolve, reject) => {
        fb.auth
          .signInWithEmailAndPassword(loginObj.email, loginObj.password)
          .then((cred) => {
            console.log("Kiosk loggedIn successfully:", cred.user.uid);
            dispatch("fetchKioskProfile", cred.user);
            router.push("/loadData");
            resolve("Successfully LoggedIn");
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },
    fetchKioskProfile({ commit, dispatch, getters }, kiosk) {
      fb.kioskCollection.doc(kiosk.uid).onSnapshot((kioskData) => {
        commit("setKioskId", kiosk.uid);
        commit("setKioskProfile", kioskData);
        // Checking refresh of the kiosk from Dahboard
        dispatch("autoRefreshKiosk", kioskData);
        // To initiate data load from the data fetched from the firebase.
        if (!getters.getDataLoadInit) {
          commit("setDataloadInit", true);
          commit(
            "setSessionId",
            crypto.randomBytes(50).toString("hex").slice(0, 10)
          );
          dispatch("initiateDataPull", kioskData);
          commit("setQnaEndpointEN", kioskData.data().englishQna);
          let hbClient = new HeartbeatClient();

          hbClient.connect({
            id: kiosk.uid,
            severity: "medium",
            singleton: true, // Set this to false if you're handling multiple instances
            frequency: "2100",
            fallback: "8410",
            instance_id: -1,
            secret:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhcnMiLCJpYXQiOjE3MjQ0NDY2OTJ9.-d0BoZYHLPiVf66kHr_ZfiO_O8UwoVTb0sP1EXib3Jc",
          });
        }
      });
    },

    logout({ commit }) {
      return new Promise((resolve, reject) => {
        fb.auth
          .signOut()
          .then(() => {
            commit("setKioskId", undefined);
            commit("setKioskProfile", {});
            commit("setDataloadInit", false);
            resolve("Successfully Logged Out");
            location.reload();
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },
    manageKioskPresence({ state }, kiosk) {
      var kioskStatusDatabaseRef = fb.firebase
        .database()
        .ref("/status/" + kiosk.uid);
      console.log(state.kioskId);

      var isOfflineForDatabase = {
        state: "Unavailable",
        last_changed: fb.firebase.database.ServerValue.TIMESTAMP,
      };

      var isOnlineForDatabase = {
        state: "Available",
        last_changed: fb.firebase.database.ServerValue.TIMESTAMP,
      };

      fb.firebase
        .database()
        .ref(".info/connected")
        .on("value", function (snapshot) {
          // If we're not currently connected, don't do anything.
          if (snapshot.val() === false) return;

          kioskStatusDatabaseRef
            .onDisconnect()
            .set(isOfflineForDatabase)
            .then(function () {
              kioskStatusDatabaseRef.set(isOnlineForDatabase);
            });
        });
    },
    sendEmail({ state }, emailData) {
      console.log(state.kioskId);
      return new Promise((resolve, reject) => {
        const mailObj = {
          to: emailData.address,
          message: {
            subject: emailData.subject,
            html: emailData.content,
          },
        };

        var config = {
          method: "post",
          url: "https://us-central1-connect-dashboard.cloudfunctions.net/apiV2/mail/a763cd056f1b2405788443b7197e0708",
          headers: {
            "Content-Type": "application/json",
          },
          data: mailObj,
        };

        axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));
            resolve("Email sent successfully");
          })
          .catch(function (error) {
            console.log(error);
            reject(error.message);
          });
        // fb.mailCollection.add(mailObj)
        //   .then(() => {
        //     resolve('Email sent successfully')
        //   })
        //   .catch(error => {
        //     reject(error.message)
        //   })
      });
    },
    sendQnaData({ state, commit }, data) {
      fb.qnaCollection
        .add(data)
        .then((response) => {
          console.log(state.kioskId);
          //commit('setUserQnaRequest', response.id);
          console.log("QNA analytics added successfully");
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
    autoRefreshKiosk({ state }, kiosk) {
      fb.kioskCollection.doc(kiosk.id).onSnapshot((snapshot) => {
        if (snapshot.data().autoRefresh) {
          fb.kioskCollection
            .doc(kiosk.id)
            .update({
              autoRefresh: false,
            })
            .then(() => {
              location.reload();
            });
        }
      });
    },
  },
};
